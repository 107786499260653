<template>
    <button class="btn" :class="classList" @click="setModal(true)">
        {{ $t(`${type}`) }}
    </button>
</template>

<script>
    import { mutations} from '@/store.js';
    export default {
        props: {
            classList: {
                type: String
            },
            type: {
              type: String,
              default: "Введите текст"
          }
        },
        methods: {
            ...mutations,
        }
    }
</script>